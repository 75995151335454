import React, { useState, useEffect, useRef } from "react";
import storyData from "./story.json";
import "./styles.css";

function App() {
  const [history, setHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [currentEnding, setCurrentEnding] = useState("");
  const [currentChoices, setCurrentChoices] = useState([]);
  const currentTextRef = useRef(null);

  const scrollToBottom = () => {
    if (currentTextRef.current) {
      currentTextRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [currentText]);

  useEffect(() => {
    loadPage(currentPage);
  }, [currentPage]);

  const loadPage = (pageNumber) => {
    const page = storyData.pages.find(
      (page) => page.page_number === pageNumber
    );
    if (page) {
      setCurrentText(page.text);
      setCurrentChoices(page.choices || []);
      if (page.ending) {
        setCurrentEnding(page.ending);
      } else {
        setCurrentEnding("");
      }
    }
  };

  const makeChoice = (page, choiceText) => {
    setHistory([...history, { text: currentText, choice: choiceText }]);
    setCurrentPage(page);
  };

  const restartGame = () => {
    setHistory([]);
    setCurrentPage(0);
  };

  return (
    <div className="app">
      <h2>{storyData.title}</h2>
      <div className="author">by {storyData.author}</div>
      <div className="container">
        <div className="history">
          {history.map((item, index) => (
            <div key={index}>
              <p
                className="prev-text"
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
              <p className="prev-choice">&gt; {item.choice}</p>
              <hr />
            </div>
          ))}
        </div>
        <div ref={currentTextRef} />
        <div className="current-text">
          <p dangerouslySetInnerHTML={{ __html: currentText }} />{" "}
        </div>
        {currentEnding && (
          <div className="current-ending">
            <p>{currentEnding}</p>
            <p>The End</p>
            <button className="restart-btn" onClick={restartGame}>
              Restart
            </button>
          </div>
        )}
      </div>
      <div className="choices-container">
        {currentChoices.map((choice) => (
          <button
            className="current-choice"
            key={choice.page}
            onClick={() => makeChoice(choice.page, choice.text)}
          >
            {choice.text}
          </button>
        ))}
      </div>
    </div>
  );
}

export default App;
